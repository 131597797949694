import React, { useContext, useState } from 'react';
import { Layout, Seo } from 'components';
import { Flex, Box } from 'reflexbox';
import { Button } from 'rebass';

import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import { VideoModal } from 'components/video-modal';

import patBro from 'images/patient/pages/resources/resources-pat-brochure.png';
import patGuide from 'images/patient/pages/resources/resources-pat-med-guide.png';
import videoPoster from 'images/patient/pages/resources/resources-video-poster.png';
import instructionsForUse from 'images/patient/pages/resources/resources-instructions-for-use.png';
import discussionGuide from 'images/patient/pages/resources/resources-userguide.png';
import spanishBrochure from 'images/patient/pages/resources/sprix-spanish-brochure-cover.png';
// import page style
import './resources.scss';

const pageTitle = 'HCP Resources';
const pageDescription = '';

const trackingGuide = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'Patient Resources Discussion Guide button',
    'Patient Resources Discussion Guide ',
  );
};
const trackingPatientBrochure = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'Patient Resources Brochure button',
    'Patient Resources Patient Brochure',
  );
};
const trackingPatientMedGuide = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'Patient Resources Med Guide button',
    'Patient Resources Med Guide ',
  );
};
const trackingHowToUse = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'Patient Resources Intructions for Use button',
    'Patient Resources Instructions for Use',
  );
};

const ResouresPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const [showModal, setShowModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  const handleClick = (url, e) => {
    e.persist();
    setSelectedVideoUrl(url);
    setShowModal(true);
  };

  const handleClose = () => {
    setSelectedVideoUrl('');
    setShowModal(false);
  };

  return (
    <Layout indication={siteIndications.patient} className="patient-resources">
      <Seo pageTitle="Patient Resources Page" pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <ContentBlock>
            <div id="downloads">
              <h2>
                <strong>RESOURCES</strong>
              </h2>
            </div>
          </ContentBlock>
          <div className="lt-blue">
            <div className="resource-container">
              <ContentBlock>
                <div className="box-container">
                  <Flex>
                    <Box xs={3} className="first-block">
                      <img className="smaller" src={discussionGuide} />
                    </Box>
                    <Box xs={7} ml={4} className="second-block">
                      <p className="no-tb-pad standard-bottom-spacing">
                        SPRIX<sup>&reg;</sup> (ketorolac tromethamine)
                      </p>
                      <p className="no-tb-pad">Doctor Discussion Guide</p>
                      <p className="smaller standard-vertical-spacing">
                        Start a conversation with your healthcare professional
                        using this helpful guide
                      </p>
                      <a
                        href={'/pdfs/SPRIX_Discussion_Guide.pdf'}
                        target={'_blank'}
                        onClick={trackingGuide}
                      >
                        <Button backgroundColor={'#19386e'} color={'#f7db4b'}>
                          Download the Doctor Discussion Guide
                        </Button>
                      </a>
                    </Box>
                  </Flex>
                </div>
              </ContentBlock>
            </div>
          </div>
          <div className="empty-space-1rem"></div>
          <div className="gray">
            <div className="resource-container">
              <ContentBlock>
                <div className="box-container">
                  <Flex>
                    <Box xs={3} className="first-block">
                      <img className="pat-brochure" src={patBro} />
                    </Box>
                    <Box xs={7} ml={4} className="second-block">
                      <p className="blue-copy">
                        SPRIX<sup>&reg;</sup> Patient Brochure{' '}
                      </p>
                      <a
                        href={'/pdfs/SPRIX_Patient_Brochure.pdf'}
                        target={'_blank'}
                        onClick={trackingPatientBrochure}
                      >
                        <Button backgroundColor={'#19386e'} color={'#f7db4b'}>
                          Download the Patient Brochure
                        </Button>
                      </a>
                    </Box>
                  </Flex>
                </div>
              </ContentBlock>
            </div>
          </div>
          <div className="empty-space-1rem"></div>
          <div className="lt-blue">
            <div className="resource-container">
              <ContentBlock>
                <div className="box-container">
                  <Flex>
                    <Box xs={3} className="first-block">
                      <img src={spanishBrochure} />
                    </Box>
                    <Box xs={7} ml={4} className="second-block">
                      <p>
                        SPRIX<sup>®</sup> Patient Brochure – Spanish
                      </p>
                      <a
                        href={'/pdfs/SPRIX_Patient_Brochure_Spanish.pdf'}
                        target={'_blank'}
                        // onClick={trackingSpanishCoreAid}
                      >
                        <Button backgroundColor={'#19386e'} color={'#f7db4b'}>
                          Download Patient Brochure – Spanish
                        </Button>
                      </a>
                    </Box>
                  </Flex>
                </div>
              </ContentBlock>
            </div>
          </div>
          <div className="empty-space-1rem"></div>
          <div className="gray">
            <div className="resource-container">
              <ContentBlock>
                <div className="box-container">
                  <Flex>
                    <Box xs={3} className="first-block">
                      <img src={patGuide} />
                    </Box>
                    <Box xs={7} ml={4} className="second-block">
                      <p className="blue-copy">
                        SPRIX<sup>&reg;</sup> Patient Medication Guide
                      </p>
                      <a
                        href={'/pdfs/SPRIX_Patient_Med_Guide.pdf'}
                        target={'_blank'}
                        onClick={trackingPatientMedGuide}
                      >
                        <Button backgroundColor={'#19386e'} color={'#f7db4b'}>
                          Download the Patient Medication Guide
                        </Button>
                      </a>
                    </Box>
                  </Flex>
                </div>
              </ContentBlock>
            </div>
          </div>
          <div className="empty-space-1rem"></div>
          <div className="lt-blue">
            <div className="resource-container">
              <ContentBlock>
                <div className="box-container">
                  <Flex className="row-container">
                    <Box xs={3} className="first-block">
                      <img src={instructionsForUse} />
                    </Box>
                    <Box xs={7} ml={4} className="second-block">
                      <p>
                        SPRIX<sup>&reg;</sup> Patient Instructions for Use{' '}
                      </p>
                      <a
                        href={
                          '/pdfs/SPRIX-Instructions-For-Use-Sheet-And-PI-Pgs.pdf'
                        }
                        target={'_blank'}
                        onClick={trackingHowToUse}
                      >
                        <Button backgroundColor={'#19386e'} color={'#f7db4b'}>
                          Download the Patient Instructions for Use
                        </Button>
                      </a>
                    </Box>
                  </Flex>
                </div>
              </ContentBlock>
            </div>
          </div>
          <div className="empty-space-1rem"></div>
          <div className="dark-blue" id="video">
            <ContentBlock>
              <h2 className="white">video</h2>
              <Flex className="row-container">
                <Box xs={12} md={6} className="first-block">
                  <img
                    src={videoPoster}
                    onClick={e =>
                      handleClick('https://player.vimeo.com/video/222721740', e)
                    }
                  />
                </Box>
                <Box xs={12} ml={5} className="second-block">
                  <p>
                    How to Use SPRIX<sup>&reg;</sup>{' '}
                  </p>
                </Box>
              </Flex>
            </ContentBlock>
            <VideoModal
              closeModal={handleClose}
              open={showModal}
              url={selectedVideoUrl}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResouresPage;
